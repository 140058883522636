import React from "react"
import styled from "styled-components"

import { Container } from "../components/global"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"

const AboutUsPage = () => (
  <Layout>
    <SEO title="About Us" />
    <Navigation />
    <StyledContainer>
      <HeaderWrapper>
        <Subtitle>Despre noi</Subtitle>
        Suntem o companie care doreste sa reduca povara administrativa de pe
        umerii antreprenorilor.
        <br />
        Poate iti doresti sa faci ceea ce stii, nu sa alergi cu acte pana la
        contabil.
      </HeaderWrapper>
    </StyledContainer>
    <Footer />
  </Layout>
)

export default AboutUsPage

const StyledContainer = styled(Container)``

const HeaderWrapper = styled.header`
  padding: 160px 0 80px 0;
  position: relative;
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`
const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`
